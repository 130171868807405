@font-face {
  font-family: 'Nishiki-teki Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Nishiki-teki Regular'), url('assets/NishikiTeki-MVxaJ.woff') format('woff');
  }

body {
  font-family: 'Nishiki-teki Regular', sans-serif;
  background-color: #08100C;
  color: white;
}

h1 {
  font-size: 100px;
}

a {
  color: white;
}
